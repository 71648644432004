export const environment = {
  production: true,
  svAPIKey: "JLV321",
  baseUrl: "https://production.public.api.lafka.dev/api/v1",
  googleAppId: "",
  facebookAppId: "",
  firebase: {
    apiKey: "AIzaSyBIdsOBO2T2nrJrfnfv13_Z_NKYWQkGTG4",
    authDomain: "jaloviini-prod.firebaseapp.com",
    projectId: "jaloviini-prod",
    storageBucket: "jaloviini-prod.appspot.com",
    messagingSenderId: "842437966102",
    appId: "1:842437966102:web:9a3c3ef8acfddb869bb837",
    measurementId: "G-88WV0E5RH7",
  },
};
